"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAbsoluteDependencies = exports.getAbsoluteDependency = exports.isValidSemver = exports.isAbsoluteVersion = exports.fetchPackageJSON = void 0;
const semver_1 = require("semver");
function fetchWithRetries(url) {
    return __awaiter(this, void 0, void 0, function* () {
        let err;
        for (let i = 0; i < 5; i++) {
            try {
                // eslint-disable-next-line
                return yield fetch(url).then(x => {
                    if (x.ok) {
                        return x.json();
                    }
                    throw new Error('Could not fetch ' + url);
                });
            }
            catch (e) {
                err = e;
            }
        }
        throw err;
    });
}
function fetchPackageJSON(dep, version) {
    return __awaiter(this, void 0, void 0, function* () {
        const fetchJsdelivr = () => fetchWithRetries(`https://cdn.jsdelivr.net/npm/${dep}@${encodeURIComponent(version)}/package.json`);
        const fetchUnpkg = () => fetchWithRetries(`https://unpkg.com/${dep}@${encodeURIComponent(version)}/package.json`);
        if (isAbsoluteVersion(version)) {
            try {
                return yield fetchJsdelivr();
            }
            catch (e) {
                return fetchUnpkg();
            }
        }
        else {
            // If it is not an absolute version (e.g. a tag like `next`), we don't want to fetch
            // using JSDelivr, because JSDelivr caches the response for a long time. Because of this,
            // when a tag updates to a new version, people won't see that update for a long time.
            // Unpkg does handle this nicely, but is less stable. So we default to JSDelivr, but
            // for tags we use unpkg.
            try {
                return yield fetchUnpkg();
            }
            catch (e) {
                return fetchJsdelivr();
            }
        }
    });
}
exports.fetchPackageJSON = fetchPackageJSON;
function isAbsoluteVersion(version) {
    const isAbsolute = /^\d+\.\d+\.\d+$/.test(version);
    return isAbsolute || /\//.test(version);
}
exports.isAbsoluteVersion = isAbsoluteVersion;
function isValidSemver(version) {
    return Boolean(semver_1.valid(version));
}
exports.isValidSemver = isValidSemver;
function getAbsoluteDependency(depName, depVersion) {
    return __awaiter(this, void 0, void 0, function* () {
        if (isAbsoluteVersion(depVersion)) {
            return { name: depName, version: depVersion };
        }
        let data;
        if (depName === 'cerebral' && depVersion === 'latest') {
            // Bug in JSDelivr, this returns the wrong package.json (of a beta version). So use Unpkg
            data = yield fetchWithRetries(`https://unpkg.com/cerebral@${encodeURIComponent('latest')}/package.json`);
        }
        else {
            data = yield fetchPackageJSON(depName, depVersion);
        }
        return { name: depName, version: data.version };
    });
}
exports.getAbsoluteDependency = getAbsoluteDependency;
function getAbsoluteDependencies(dependencies) {
    return __awaiter(this, void 0, void 0, function* () {
        const nonAbsoluteDependencies = Object.keys(dependencies).filter(dep => !isAbsoluteVersion(dependencies[dep]));
        const newDependencies = Object.assign({}, dependencies);
        yield Promise.all(nonAbsoluteDependencies.map((dep) => __awaiter(this, void 0, void 0, function* () {
            try {
                const { version } = yield getAbsoluteDependency(dep, newDependencies[dep]);
                newDependencies[dep] = version;
            }
            catch (e) {
                /* ignore */
            }
        })));
        return newDependencies;
    });
}
exports.getAbsoluteDependencies = getAbsoluteDependencies;
