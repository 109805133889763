"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.getTimestamp = void 0;
// This is .js for preval
var versionType = "DEV";
var versionNumber = Math.floor(1635781537246 / 1000);
var shortCommitSha = "unknown";

var getTimestamp = function getTimestamp(version) {
  return +version.split('-')[1];
};

exports.getTimestamp = getTimestamp;
var _default = "DEV-1635781537-unknown";
exports.default = _default;