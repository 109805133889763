var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Responsible for consuming and syncing with the server/local cache
import localforage from 'localforage';
import * as memoryDriver from 'localforage-driver-memory';
import _debug from '@codesandbox/common/lib/utils/debug';
const debug = _debug('cs:compiler:cache');
const host = process.env.CODESANDBOX_HOST;
localforage.defineDriver(memoryDriver);
localforage.setDriver([
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    localforage.WEBSQL,
    memoryDriver._driver,
]);
const MAX_CACHE_SIZE = 1024 * 1024 * 20;
let APICacheUsed = false;
try {
    localforage.config({
        name: 'CodeSandboxApp',
        storeName: 'sandboxes',
        description: 'Cached transpilations of the sandboxes, for faster initialization time.',
    });
    // Prewarm store
    localforage.keys();
}
catch (e) {
    console.warn('Problems initializing IndexedDB store.');
    console.warn(e);
}
function shouldSaveOnlineCache(firstRun, changes) {
    if (!firstRun || changes > 0) {
        return false;
    }
    if (!window.__SANDBOX_DATA__) {
        return true;
    }
    return false;
}
export function clearIndexedDBCache() {
    return localforage.clear();
}
export function saveCache(managerModuleToTranspile, manager, changes, firstRun) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!manager.id) {
            return Promise.resolve(false);
        }
        const managerState = Object.assign({}, (yield manager.serialize({
            entryPath: managerModuleToTranspile
                ? managerModuleToTranspile.path
                : null,
            optimizeForSize: true,
        })));
        try {
            if (process.env.NODE_ENV === 'development') {
                debug('Saving cache of ' +
                    (JSON.stringify(managerState).length / 1024).toFixed(2) +
                    'kb to indexedDB');
            }
            yield localforage.setItem(manager.id, managerState);
        }
        catch (e) {
            if (process.env.NODE_ENV === 'development') {
                console.error(e);
            }
            manager.clearCache();
        }
        if (shouldSaveOnlineCache(firstRun, changes)) {
            const stringifiedManagerState = JSON.stringify(managerState);
            if (stringifiedManagerState.length > MAX_CACHE_SIZE) {
                return Promise.resolve(false);
            }
            debug('Saving cache of ' +
                (stringifiedManagerState.length / 1024).toFixed(2) +
                'kb to CodeSandbox API');
            return window
                .fetch(`${host}/api/v1/sandboxes/${manager.id}/cache`, {
                method: 'POST',
                body: JSON.stringify({
                    version: manager.version,
                    data: stringifiedManagerState,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(x => x.json())
                .catch(e => {
                if (process.env.NODE_ENV === 'development') {
                    console.error('Something went wrong while saving cache.');
                    console.error(e);
                }
            });
        }
        return Promise.resolve(false);
    });
}
export function deleteAPICache(sandboxId, version) {
    if (APICacheUsed && !process.env.SANDPACK) {
        debug('Deleting cache of API');
        return window
            .fetch(`${host}/api/v1/sandboxes/${sandboxId}/cache`, {
            method: 'DELETE',
            body: JSON.stringify({
                version,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(x => x.json())
            .catch(e => {
            console.error('Something went wrong while deleting cache.');
            console.error(e);
        });
    }
    return Promise.resolve(false);
}
function findCacheToUse(cache1, cache2) {
    if (!cache1 && !cache2) {
        return null;
    }
    if (cache1 && !cache2) {
        return cache1;
    }
    if (cache2 && !cache1) {
        return cache2;
    }
    return cache2.timestamp > cache1.timestamp ? cache2 : cache1;
}
export function ignoreNextCache() {
    try {
        localStorage.setItem('ignoreCache', 'true');
    }
    catch (e) {
        console.warn(e);
    }
}
export function consumeCache(manager) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!manager.id) {
            return false;
        }
        try {
            const shouldIgnoreCache = localStorage.getItem('ignoreCache') ||
                localStorage.getItem('ignoreCacheDev');
            if (shouldIgnoreCache) {
                localStorage.removeItem('ignoreCache');
                return false;
            }
            const cacheData = window.__SANDBOX_DATA__;
            const localData = yield localforage.getItem(manager.id);
            const cache = findCacheToUse(cacheData && cacheData.data, localData);
            if (cache) {
                if (cache.version === manager.version) {
                    if (cache === localData) {
                        APICacheUsed = false;
                    }
                    else {
                        APICacheUsed = true;
                    }
                    debug(`Loading cache from ${cache === localData ? 'IndexedDB' : 'API'}`, cache);
                    yield manager.load(cache);
                    return true;
                }
            }
            return false;
        }
        catch (e) {
            console.warn('Problems consuming cache');
            console.warn(e);
            return false;
        }
    });
}
