var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as pathUtils from '@codesandbox/common/lib/utils/path';
import resolve from 'browser-resolve';
import DependencyNotFoundError from 'sandbox-hooks/errors/dependency-not-found-error';
import { getFetchProtocol } from './fetch-protocols';
import { getDependencyName } from '../../utils/get-dependency-name';
import { packageFilter } from '../../utils/resolve-utils';
import { DEFAULT_EXTENSIONS } from '../../utils/extensions';
const metas = {};
export let combinedMetas = {}; // eslint-disable-line
const normalizedMetas = {};
const packages = {};
function prependRootPath(meta, rootPath) {
    const newMeta = {};
    Object.keys(meta).forEach(path => {
        newMeta[rootPath + path] = meta[path];
    });
    return newMeta;
}
export function setCombinedMetas(givenCombinedMetas) {
    combinedMetas = givenCombinedMetas;
}
// Strips the version of a path, eg. test/1.3.0 -> test
const ALIAS_REGEX = /\/\d*\.\d*\.\d*.*?(\/|$)/;
/*
 * Resolve name and version from npm aliases
 * e.g. "react": "npm:preact-compat@16.0.0"
 */
const resolveNPMAlias = (name, version) => {
    const IS_ALIAS = /^npm:/;
    if (!version.match(IS_ALIAS)) {
        return [name, version];
    }
    const parts = version.match(/^npm:(.+)@(.+)/);
    return [parts[1], parts[2]];
};
function getMeta(name, packageJSONPath, version, useFallback = false) {
    const [depName, depVersion] = resolveNPMAlias(name, version);
    const nameWithoutAlias = depName.replace(ALIAS_REGEX, '');
    const id = `${packageJSONPath || depName}@${depVersion}`;
    if (metas[id]) {
        return metas[id];
    }
    const protocol = getFetchProtocol(depName, depVersion, useFallback);
    metas[id] = protocol.meta(nameWithoutAlias, depVersion).catch(e => {
        delete metas[id];
        throw e;
    });
    return metas[id];
}
export function downloadDependency(name, version, path) {
    const [depName, depVersion] = resolveNPMAlias(name, version);
    const id = depName + depVersion + path;
    if (packages[id]) {
        return packages[id];
    }
    const relativePath = path
        .replace(new RegExp(`.*${pathUtils.join('/node_modules', depName)}`.replace('/', '\\/')), '')
        .replace(/#/g, '%23');
    const nameWithoutAlias = depName.replace(ALIAS_REGEX, '');
    const protocol = getFetchProtocol(depName, depVersion);
    packages[id] = protocol
        .file(nameWithoutAlias, depVersion, relativePath)
        .catch(() => __awaiter(this, void 0, void 0, function* () {
        const fallbackProtocol = getFetchProtocol(nameWithoutAlias, depVersion, true);
        return fallbackProtocol.file(nameWithoutAlias, depVersion, relativePath);
    }))
        .then(code => ({
        path,
        code,
        downloaded: true,
    }));
    return packages[id];
}
function resolvePath(path, currentTModule, manager, defaultExtensions = DEFAULT_EXTENSIONS, meta = {}) {
    const currentPath = currentTModule.module.path;
    const isFile = (p, c, cb) => {
        const callback = cb || c;
        const result = Boolean(manager.transpiledModules[p]) || Boolean(meta[p]);
        if (!callback) {
            return result;
        }
        return callback(null, result);
    };
    return new Promise((res, reject) => {
        resolve(path, {
            filename: currentPath,
            extensions: defaultExtensions.map(ext => '.' + ext),
            packageFilter,
            moduleDirectory: [
                'node_modules',
                manager.envVariables.NODE_PATH,
            ].filter(Boolean),
            isFile,
            // @ts-ignore
            readFile: (p, c, cb) => __awaiter(this, void 0, void 0, function* () {
                const callback = cb || c;
                try {
                    const tModule = yield manager.resolveTranspiledModule(p, '/', []);
                    tModule.initiators.add(currentTModule);
                    currentTModule.dependencies.add(tModule);
                    return callback(null, tModule.module.code);
                }
                catch (e) {
                    const depPath = p.replace(/.*\/node_modules\//, '');
                    const depName = getDependencyName(depPath);
                    // To prevent infinite loops we keep track of which dependencies have been requested before.
                    if (!manager.transpiledModules[p] && !meta[p]) {
                        const err = new Error('Could not find ' + p);
                        // @ts-ignore
                        err.code = 'ENOENT';
                        return callback(err);
                    }
                    // eslint-disable-next-line
                    const subDepVersionVersionInfo = yield getDependencyVersion(currentTModule, manager, depName);
                    if (subDepVersionVersionInfo) {
                        const { version: subDepVersion } = subDepVersionVersionInfo;
                        try {
                            const module = yield downloadDependency(depName, subDepVersion, p);
                            if (module) {
                                manager.addModule(module);
                                const tModule = manager.addTranspiledModule(module, '');
                                tModule.initiators.add(currentTModule);
                                currentTModule.dependencies.add(tModule);
                                callback(null, module.code);
                                return null;
                            }
                        }
                        catch (er) {
                            // Let it throw the error
                        }
                    }
                    return callback(e);
                }
            }),
        }, (err, resolvedPath) => {
            if (err) {
                return reject(err);
            }
            return res(resolvedPath);
        });
    });
}
function getDependencyVersion(currentTModule, manager, dependencyName) {
    return __awaiter(this, void 0, void 0, function* () {
        const { manifest } = manager;
        try {
            const foundPackageJSONPath = yield resolvePath(pathUtils.join(dependencyName, 'package.json'), currentTModule, manager, []);
            // If the dependency is in the root we get it from the manifest, as the manifest
            // contains all the versions that we really wanted to resolve in the first place.
            // An example of this is csb.dev packages, the package.json version doesn't say the
            // actual version, but the semver it relates to. In this case we really want to have
            // the actual url
            if (foundPackageJSONPath ===
                pathUtils.join('/node_modules', dependencyName, 'package.json')) {
                const rootDependency = manifest.dependencies.find(dep => dep.name === dependencyName);
                if (rootDependency) {
                    return {
                        packageJSONPath: foundPackageJSONPath,
                        version: rootDependency.version,
                    };
                }
            }
            const packageJSON = manager.transpiledModules[foundPackageJSONPath] &&
                manager.transpiledModules[foundPackageJSONPath].module.code;
            const { version } = JSON.parse(packageJSON);
            const savedDepDep = manifest.dependencyDependencies[dependencyName];
            if (savedDepDep &&
                savedDepDep.resolved === version &&
                savedDepDep.semver.startsWith('https://')) {
                return {
                    packageJSONPath: foundPackageJSONPath,
                    version: savedDepDep.semver,
                };
            }
            if (packageJSON !== '//empty.js') {
                return { packageJSONPath: foundPackageJSONPath, version };
            }
        }
        catch (e) {
            /* do nothing */
        }
        let version = null;
        if (manifest.dependencyDependencies[dependencyName]) {
            if (manifest.dependencyDependencies[dependencyName].semver.startsWith('https://')) {
                version = manifest.dependencyDependencies[dependencyName].semver;
            }
            else {
                version = manifest.dependencyDependencies[dependencyName].resolved;
            }
        }
        else {
            const dep = manifest.dependencies.find(m => m.name === dependencyName);
            if (dep) {
                // eslint-disable-next-line
                version = dep.version;
            }
        }
        if (version) {
            return { packageJSONPath: null, version };
        }
        return null;
    });
}
export default function fetchModule(path, currentTModule, manager, defaultExtensions = DEFAULT_EXTENSIONS) {
    return __awaiter(this, void 0, void 0, function* () {
        const currentPath = currentTModule.module.path;
        // Get the last part of the path as dependency name for paths like
        // instantsearch.js/node_modules/lodash/sum.js
        // In this case we want to get the lodash dependency info
        const dependencyName = getDependencyName(path.replace(/.*\/node_modules\//, ''));
        const versionInfo = yield getDependencyVersion(currentTModule, manager, dependencyName);
        if (versionInfo === null) {
            throw new DependencyNotFoundError(path);
        }
        const { packageJSONPath, version } = versionInfo;
        let meta;
        try {
            meta = yield getMeta(dependencyName, packageJSONPath, version);
        }
        catch (e) {
            // Use fallback
            meta = yield getMeta(dependencyName, packageJSONPath, version, true);
        }
        const rootPath = packageJSONPath
            ? pathUtils.dirname(packageJSONPath)
            : pathUtils.join('/node_modules', dependencyName);
        const normalizedCacheKey = dependencyName + rootPath;
        const normalizedMeta = normalizedMetas[normalizedCacheKey] || prependRootPath(meta, rootPath);
        if (!normalizedMetas[normalizedCacheKey]) {
            normalizedMetas[normalizedCacheKey] = normalizedMeta;
        }
        else {
            combinedMetas = Object.assign(Object.assign({}, combinedMetas), normalizedMeta);
        }
        const foundPath = yield resolvePath(path, currentTModule, manager, defaultExtensions, normalizedMeta);
        if (foundPath === '//empty.js') {
            // Mark the path of the module as the real module, because during evaluation
            // we don't have meta to find which modules are browser modules and we still
            // need to return an empty module for browser modules.
            const isDependency = /^(\w|@\w|@-)/.test(path);
            const fullFilePath = isDependency
                ? pathUtils.join('/node_modules', path)
                : pathUtils.join(currentPath, path);
            return {
                path: fullFilePath,
                code: 'module.exports = {};',
                requires: [],
                stubbed: true,
            };
        }
        return downloadDependency(dependencyName, version, foundPath);
    });
}
